import CookieService from "src/utils/CookieService";
import ls from "localstorage-slim";
import {isSessionInvalid} from "src/utils/Authentication";
import {setAdfsSessionCookies} from "src/utils/CookieUtils";
import {strToU8, zlibSync} from "fflate";

export const getAdfsAccessToken = async () => {
    let adfsAccessToken = CookieService.get("adfs-access-token") || ls.get("adfs-access-token");
    const refreshToken = CookieService.get("adfs-refresh-token") || ls.get("adfs-refresh-token");

    if (!adfsAccessToken || isSessionInvalid(adfsAccessToken)) {
        if (await setAdfsSessionCookies(refreshToken)) {
            adfsAccessToken = CookieService.get("adfs-access-token") || ls.get("adfs-access-token");
        } else {
            window.location.href = '/';
        }

    }
    return compressToken(adfsAccessToken);
}

const compressToken = (accessToken: string) => {
    const buffer = strToU8(accessToken);
    return zlibSync(buffer).toString();
}


