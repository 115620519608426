import AWS from 'aws-sdk';
import * as aws4 from 'aws4';
import { getAdfsAccessToken } from "./AdfsUtils";
import { apiGatewayRoleArn } from "src/constants/EndpointStageMapping"

let cachedCredentials: AWS.STS.Credentials | undefined;
let expiration: Date | undefined;
const apiRoleArn: string = apiGatewayRoleArn["beta"];
async function getTemporaryCredentials(): Promise<AWS.STS.Credentials> {
    if (cachedCredentials && expiration && new Date() < expiration) {
        return cachedCredentials;
    }

    const sts = new AWS.STS({region: 'us-east-1'});
    const params = {
        RoleArn: apiRoleArn,
        RoleSessionName: 'mdw-session',
    };
    try {
        const data = await sts.assumeRole(params).promise();
        if (!data.Credentials || !data.Credentials.Expiration) {
            console.error(`Could not assume role. params:${params}, data:${data}`);
            throw new Error("Failed to assume role or expiration date is missing.");
        }
        cachedCredentials = data.Credentials;
        expiration = new Date(cachedCredentials.Expiration);

        AWS.config.update({
            accessKeyId: cachedCredentials.AccessKeyId,
            secretAccessKey: cachedCredentials?.SecretAccessKey,
            sessionToken: cachedCredentials?.SessionToken,
        });
    } catch (err) {
        console.error('Error assuming role', err);
        throw err;
    }
    return cachedCredentials;
}

export async function signedRequest(url: string, options: RequestInit) {
    const credentials = await getTemporaryCredentials();


    // Ensure the body is a string if it exists
    const body = options.body ? (typeof options.body === 'string' ? options.body : JSON.stringify(options.body)) : undefined;

    const opts = {
        url,
        service: 'execute-api',
        headers: {
            'X-API-Access-Token': await getAdfsAccessToken(),
            ...options.headers
        },
        method: options.method || 'GET',
        body
    };

    //sign the request
    aws4.sign(opts as aws4.Request, {
        accessKeyId: credentials.AccessKeyId,
        secretAccessKey: credentials.SecretAccessKey,
        sessionToken: credentials.SessionToken,
    });
    const fetchOptions = { ...options, headers: opts.headers, body: opts.body };
    return fetch(url, fetchOptions);
}


